import { Link } from 'gatsby';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { convertTagForUrl } from '../../../utils/text';
import Styles from './category-filter.module.scss';

const TagButton = ({ tag }) => (
    <Link activeClassName={Styles.activeTagButton} partiallyActive={true} to={`/blog/${convertTagForUrl(tag)}`} className={`${Styles.tagButton} unstyled-link font-weight-normal mb-3 mb-md-0`}>{tag}</Link>
);

export default ({ tags }) => {
    tags = tags.filter(tag => tag !== 'zero-to-hero');
    return (
        <Container className="mb-5 pb-5">
            <Row className="justify-content-center">
                <Col className="d-flex flex-column flex-md-row justify-content-md-center">{tags.map((tag, index) => <TagButton key={index} tag={tag}></TagButton>)}</Col>
            </Row>
        </Container>
    );
}
