import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/layout';
import LandingHeader from '../components/global/landing-header/landing-header';
import RecentPosts from '../components/global/recent-posts/recent-posts';
import CategoryFilter from '../components/blog/category-filter/category-filter';
import MailchimpSignup from '../components/global/mailchimp-signup/mailchimp-signup';

export default function ({ data }) {
  return (
    <Layout footerPosts={data.posts} title="Blog" description="Alle Posts auf einen Blick.">
        <LandingHeader
            text="Alle Posts auf einen Blick."
            backgroundImage="'/images/blog-header.svg'"
        ></LandingHeader>
        <CategoryFilter tags={data.tags.distinct}></CategoryFilter>
        <RecentPosts posts={data.posts}></RecentPosts>
        <MailchimpSignup
          alwaysVertical={false}
          outerClass="py-5"
          slice={{
            primary: {
              title1: {
                text: '5 super Tipps, um mit dem Abnehmen zu beginnen!'
              },
              text: {
                html: '<p>Zwei Seiten, gefüllt mit 5 nützlichen Tipps & Tricks, damit Du mit dem Abnehmen beginnen kannst! Trage dich jetzt ein und erhalte die PDF in wenigen Minuten. Auch super zum Ausdrucken geeignet!</p>'
              }
            }
          }}
        />
    </Layout>
  );
}

export const pageQuery = graphql`
  {
    posts: allPrismicBlogPost(limit: 10, sort: {fields: first_publication_date, order: DESC}, filter: {tags: {nin: "zero-to-hero"}}) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          title_image {
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        first_publication_date_from_now: first_publication_date(fromNow: true)
        first_publication_date_time: first_publication_date(formatString: "hh:mm")
        first_publication_date: first_publication_date(formatString: "DD.MM.YYYY")
        first_publication_date_day: first_publication_date(formatString: "dddd", locale: "de-DE")
        tags
      }
    }
    tags: allPrismicBlogPost {
      distinct(field: tags)
    }
  }
`
